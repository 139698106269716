import { combineReducers } from "redux"

import vp from "./vp"
import vt from "./vt"
import enterprise from "./enterprise"
import teams from "./teams"

const platformReducers = combineReducers({
  vp,
  vt,
  enterprise,
  teams,
})

export default platformReducers
