// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import app from './app'
import form from './form'
import platform from './platform'
import settings from './settings'

const rootReducer = combineReducers({
  app,
  form,
  platform,
  settings
})

export default rootReducer
