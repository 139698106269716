const initialState = {
  profile: null,
  members: null,
  references: null,
  applications: null,
  assessmentContext: null,
  jobApplication: null,
}

const vt = (state = initialState, { type, data }) => {
  switch (type) {
    case "VT_UPDATE_PROFILE": {
      return { ...state, profile: data }
    }
    case "VT_UPDATE_MEMBERS": {
      return { ...state, members: data }
    }
    case "VT_UPDATE_REFERENCES": {
      return { ...state, references: data }
    }
    case "VT_UPDATE_APPLICATIONS": {
      return { ...state, applications: data }
    }
    case "VT_UPDATE_ASSESSMENT_CONTEXT": {
      return { ...state, assessmentContext: data }
    }
    case "VT_UPDATE_JOB_APPLICATION": {
      return { ...state, jobApplication: data }
    }
    default: {
      return state
    }
  }
}

export default vt
