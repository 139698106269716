const initialState = {
  listings: null,
  alias: null,
  reports: null,
  applicants: null,
  filterLoading: false,
  resume: null,
  assessment: null,
  // templateList: null,
  templateActiveIndex: 1,
  // scoreChangerList: null
}

const enterpriseReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case "TEAMS_UPDATE_LISTINGS": {
      return { ...state, listings: data }
    }
    case "TEAMS_UPDATE_ALIAS": {
      return { ...state, alias: data }
    }
    case "TEAMS_TOGGLE_FILTER_LOADING": {
      return { ...state, filterLoading: data }
    }
    case "TEAMS_UPDATE_APPLICANTS": {
      return { ...state, applicants: data }
    }
    case "TEAMS_UPDATE_RESUME": {
      return { ...state, resume: data }
    }
    case "TEAMS_UPDATE_ASSESSMENT": {
      return { ...state, assessment: data }
    }
    case "TEAMS_UPDATE_REPORTS": {
      return { ...state, reports: data }
    }
    // case "TEAMS_UPDATE_TEMPLATE_LIST": {
    //   return { ...state, templateList: data }
    // }
    case "TEAMS_UPDATE_TEMPLATE_ACTIVE_INDEX": {
      return { ...state, templateActiveIndex: data }
    }
    // case "TEAMS_UPDATE_SCOREUPDATER_LIST": {
    //   return { ...state, scoreChangerList: data }
    // }
    default: {
      return state
    }
  }
}

export default enterpriseReducer
