const initialState = {
  profile: null,
  educations: null,
  experiences: null,
  references: null,
  applications: null,
  assessmentContext: null,
  jobApplication: null,
  applicationFiles: null,
  refereeEngagementLevel: null,
}

const vp = (state = initialState, { type, data }) => {
  switch (type) {
    case "VP_UPDATE_PROFILE": {
      return { ...state, profile: data }
    }
    case "VP_UPDATE_EDUCATIONS": {
      return { ...state, educations: data }
    }
    case "VP_UPDATE_EXPERIENCES": {
      return { ...state, experiences: data }
    }
    case "VP_UPDATE_REFERENCES": {
      return { ...state, references: data }
    }
    case "VP_UPDATE_APPLICATIONS": {
      return { ...state, applications: data }
    }
    case "VP_UPDATE_ASSESSMENT_CONTEXT": {
      return { ...state, assessmentContext: data }
    }
    case "VP_UPDATE_ASSESSMENT_TEMPLATE": {
      return { ...state, assessmentContext: {
        ...state.assessmentContext,
        template: data,
      } }
    }
    case "VP_UPDATE_JOB_APPLICATION": {
      return { ...state, jobApplication: data }
    }
    case "VP_UPDATE_APPLICATION_FILES": {
      return { ...state, applicationFiles: data }
    }
    case "VP_UPDATE_REFEREE_ENGAGEMENT_LEVEL": {
      return { ...state, refereeEngagementLevel: data }
    }
    default: {
      return state
    }
  }
}

export default vp
