// **  Initial State
const initialState = {
  loading: true,
  pageLoading: true,
  permission: 'public',
  subscription: 'full',
  authenticated: false,
  navProfile: null
}

const authReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case "APP_TOGGLE_LOADING": {
      return { ...state, loading: data }
    }
    case "APP_TOGGLE_PAGE_LOADING": {
      return { ...state, pageLoading: data }
    }
    case "APP_UPDATE_ROLE": {
      return { ...state, permission: data }
    }
    case "APP_TOGGLE_AUTHENTICATED": {
      return { ...state, authenticated: data }
    }
    case "APP_UPDATE_NAVIGATION_PROFILE": {
      return { ...state, navProfile: data }
    }
    default:
      return state
  }
}

export default authReducer
