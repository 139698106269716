// ** ThemeConfig Import
import themeConfig from '@configs/themeConfig'

// ** Returns Initial Menu Collapsed State
const initialMenuCollapsed = () => {
  const item = window.localStorage.getItem('menuCollapsed')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : themeConfig.layout.menu.isCollapsed
}

// ** Initial State
const initialState = {
  isRTL: themeConfig.layout.isRTL,
  menuCollapsed: initialMenuCollapsed(),
  menuHidden: themeConfig.layout.menu.isHidden,
  contentWidth: themeConfig.layout.contentWidth
}

const settingsReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case 'SETTINGS_UPDATE_CONTENT_WIDTH':
      return { ...state, contentWidth: data }
    case 'SETTINGS_TOGGLE_MENU_COLLAPSED':
      window.localStorage.setItem('menuCollapsed', data)
      return { ...state, menuCollapsed: data }
    case 'SETTINGS_TOGGLE_MENU_HIDDEN':
      return { ...state, menuHidden: data }
    case 'SETTINGS_TOGGLE_RTL':
      return { ...state, isRTL: data }
    default:
      return state
  }
}

export default settingsReducer
