const initialState = {
  activeId: null,
  activeAction: null,
  initialValue: null,
  modalActive: false, 
  wizardPage: 0,
  loading: false,
  success: null,
  error: null
}

const app = (state = initialState, { type, data }) => {
  switch (type) {
    case "FORM_UPDATE_ACTIVE_ID": {
      return { ...state, activeId: data }
    }
    case "FORM_UPDATE_ACTIVE_ACTION": {
      return { ...state, activeAction: data }
    }
    case "FORM_UPDATE_INITIAL_VALUE": {
      return { ...state, initialValue: data }
    }
    case "FORM_TOGGLE_MODAL_ACTIVE": {
      return { ...state, modalActive: data }
    }
    case "FORM_UPDATE_WIZARD_PAGE": {
      return { ...state, wizardPage: data }
    }
    case "FORM_TOGGLE_LOADING": {
      return { ...state, loading: data }
    }
    case "FORM_UPDATE_SUCCESS": {
      return { ...state, success: data }
    }
    case "FORM_UPDATE_ERROR": {
      return { ...state, error: data }
    }
    default: {
      return state
    }
  }
}

export default app
